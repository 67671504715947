import React from 'react';
import logo from './assets/logo.png';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    console.log(process.env.PUBLIC_URL);
    this.stripePromise = loadStripe('pk_test_51GxQqBI3vSbf0B8c4bI4hnidcg6rWNqE31NJjYd3bCEloMzhGAZaLRHaY3jr2ZwBKAfXj2QKl1U6oyrvYtxGfVrN00hLiRGBQg');
  }

  collectStripe() {
    this.stripePromise.then((stripe) => {
       stripe.redirectToCheckout({
          lineItems: [
            {price: 'price_1GxQvbI3vSbf0B8cc2haWDub', quantity: 1},
          ],
          mode: 'payment',
          successUrl: 'https://siliconvault.io/success',
          cancelUrl: 'https://siliconvault.io/',
          shippingAddressCollection: {
            allowedCountries: ['US', 'CA'],
          }
        }).then(function (result) {
          
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
    });
   
  }
  
  render() {
    return (
      <Router>
        <Switch>
          <Route path={process.env.PUBLIC_URL + "/success"}>
            Congrats
          </Route>
          <Route path={process.env.PUBLIC_URL}>
            <div className="App">
              <div className={"SiliconLogo"}>
                <img src={logo} width={300}/>
              </div>
              <div className={"SiliconVault"}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/-dAzeY-s4Ic" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className={"BuyNowContainer"}>
                <div className={"BuyNow"}
                     onClick={this.collectStripe.bind(this)}>
                  Buy Now
                </div>
              </div>
              <div className={"SiliconContianer"}>
                <div className={"SiliconStory"}>
                  <div className={"SiliconTitle"}>
                    The Story
                  </div>
                  <div className={"SiliconContent"}>
                    <p>
                      I first built silicon vault for  myself to get away from technology. As a maker, all I find my creativity skyrockets when I turn off the noise.
                    </p>
                    <p>
                      At first, I looked for a solution and couldn’t really find any.
                    </p>
                    <p>
                      So, I decided to make my own.
                    </p>
                    <p>
                      I use it every night for all of my devices. I wake up much more calm and collected. No more anxiety.
                    </p>
                    <p>
                      I don’t feel the need to look at my phone or laptop as soon as I wake up.
                    </p>
                    <p>
                      Instead, I make some breakfast and write first thing every morning.
                    </p>
                    <p>
                      When technology is out of sight, it’s out of mind.
                    </p>
                    <p>
                      Take back control of your time.
                    </p>
                    <p>
                        — David Daines
                      <br/>
                      Founder, Silicon Vault
                    </p>
                  </div>
                </div>
                <div className={"SiliconStory"}>
                  <div className={"SiliconTitle"}>
                    Features
                  </div>
                  <div className={"SiliconContent"}>
                    <p>
                      Digital display: <br/>
                      Touch screen display that’s always on and plugged in.
                    </p>
                    <p>
                      Locking functionality:<br/>
                      In case of an emergency, a physical
                      key will be provided.
                    </p>
                    <p>
                      Dimensions:<br/>
                      50 x 30 x 20 H/W/L
                    </p>
                    <p>
                      Money Back Gauruntee: <br/>
                      No questions asked, 30 day returns. 
                    </p>
                  </div>
                </div>
              </div>
              <div className={"BuyNowContainer"}>
                <div className={"BuyNow"}
                     onClick={this.collectStripe.bind(this)}>
                  Buy Now
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
